.support-button {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: var(--dark-green);
  color: var(--cream);
}

body.dark-mode .support-button {
  background-color: var(--cream);
  color: var(--dark-green);
}
