.password-generator {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }
  
  .password-generator h1 {
    margin-bottom: 1rem;
  }
  
  .controls {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 600px;
  }
  
  .controls label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
  }
  
  .controls input[type="number"],
  .controls input[type="checkbox"] {
    margin-left: 1rem;
  }
  
  .controls button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background-color: var(--dark-green);
    color: var(--cream);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  body.dark-mode .controls button {
    background-color: var(--cream);
    color: var(--dark-green);
  }
  
  .output {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 600px;
  }
  
  .output label {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .output input[type="text"] {
    margin-top: 0.5rem;
    padding: 0.5rem;
    border: 1px solid var(--dark-green);
    border-radius: 4px;
    background-color: var(--light-mode-bg);
    color: var(--dark-green);
    width: 100%;
    resize: none;
  }
  
  body.dark-mode .output input[type="text"] {
    background-color: var(--cream);
    color: var(--dark-green);
  }
  
  .output button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    background-color: var(--dark-green);
    color: var(--cream);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
  }
  
  body.dark-mode .output button {
    background-color: var(--cream);
    color: var(--dark-green);
  }
  