.css-editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.css-editor h1 {
  margin-bottom: 1rem;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2rem;
  width: 100%;
  max-width: 600px;
}

.controls label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.controls input[type="color"],
.controls input[type="number"],
.controls input[type="text"],
.controls select {
  margin-left: 1rem;
  flex: 1;
  padding: 0.3rem;
  border: 1px solid var(--dark-green);
  border-radius: 4px;
  background-color: var(--light-mode-bg);
  color: var(--dark-green);
}

body.dark-mode .controls input[type="color"],
body.dark-mode .controls input[type="number"],
body.dark-mode .controls input[type="text"],
body.dark-mode .controls select {
  background-color: var(--cream);
  color: var(--dark-green);
}

.gradient-color {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.gradient-color button {
  margin-left: 1rem;
  padding: 0.3rem 0.5rem;
  font-size: 0.8rem;
  background-color: var(--dark-green);
  color: var(--cream);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

body.dark-mode .gradient-color button {
  background-color: var(--cream);
  color: var(--dark-green);
}

.css-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--dark-green);
  border-radius: 4px;
  padding: 1rem;
  background-color: var(--light-mode-bg);
  color: var(--dark-green);
  width: 200px;
  height: 200px;
  text-align: center;
  transition: all 0.3s ease;
}

body.dark-mode .css-preview {
  background-color: var(--cream);
  color: var(--dark-green);
}

.get-code {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  background-color: var(--dark-green);
  color: var(--cream);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

body.dark-mode .get-code {
  background-color: var(--cream);
  color: var(--dark-green);
}

.get-code:hover {
  background-color: var(--cream);
  color: var(--dark-green);
}

body.dark-mode .get-code:hover {
  background-color: var(--dark-green);
  color: var(--cream);
}

.popup {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--dark-green);
  color: var(--cream);
  padding: 10px 20px;
  border-radius: 4px;
  font-size: 1rem;
  z-index: 1000;
}

body.dark-mode .popup {
  background-color: var(--cream);
  color: var(--dark-green);
}
