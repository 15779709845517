.App {
  text-align: center;
}

.App-header {
  padding: 2rem;
  background-color: var(--light-mode-bg);
}

body.dark-mode .App-header {
  background-color: var(--dark-mode-bg);
}
