:root {
  --dark-green: #004d40;
  --cream: #f5f5dc;
  --dark-mode-bg: #004d40;
  --dark-mode-text: #f5f5dc;
  --light-mode-bg: #f5f5dc;
  --light-mode-text: #004d40;
  --navbar-light-bg: var(--dark-green);
  --navbar-light-text: var(--cream);
  --navbar-dark-bg: var(--cream);
  --navbar-dark-text: var(--dark-green);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", Arial, sans-serif;
  text-decoration: none;
  transition: color 0.1s ease, background-color 0.1s ease !important;
}

body {
  background-color: var(--light-mode-bg);
  color: var(--dark-green);
}

body.dark-mode {
  background-color: var(--dark-mode-bg);
  color: var(--dark-mode-text);
}
